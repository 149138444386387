<template lang='pug'>
    .d-inline
      span(v-if="readonly") {{value}}
      template(v-if="!readonly")
        a.text-primary(href='#' v-if="!edit" @click.prevent="startEdit") {{value}}
        a.text-primary(href='#' v-if="!edit && isBlank" @click.prevent="startEdit") (Edit)
        .input-group(v-if="edit")
          input.input-group-prepend.form-control.form-control-sm(v-if="type !== 'textarea'" v-model="currentValue" @keypress.enter="done($event)" @keyup.esc="cancel()" :type="type" :min="min" :max="max" ref="input")
          textarea.input-group-prepend.form-control.form-control-sm(v-if="type === 'textarea'" v-model="currentValue" @keypress.enter="done($event)" @keyup.esc="cancel()" ref="input")
          button.input-group-append.btn.btn-sm.btn-light(@click.prevent="done($event)") OK
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class EditableColumn extends Vue {
  edit = false
  initValue: string
  currentValue: string

  @Prop() value: string
  @Prop() type: string
  @Prop() min: string | number
  @Prop() max: string | number
  @Prop() readonly: boolean

  complete () {
    this.edit = false
  }

  private startEdit () {
    this.edit = true
    this.currentValue = this.value
    this.initValue = this.value
    this.$nextTick(() => {
      (this.$refs.input as HTMLElement).focus()
    })
  }

  private done (event) {
    if (!event.shiftKey) {
      this.$emit('done', this.currentValue, this)
    }
  }

  private cancel () {
    this.$emit('input', this.initValue)
    this.edit = false
  }

  private get isBlank () {
    return this.value === ''
  }
}
</script>
