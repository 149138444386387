<template lang="pug">
    vo-basic(:data="data" direction="l2r" :toggleCollapse="false")
</template>

<script lang="ts">
  import Vue from 'vue'
  import $ from 'jquery'
  import 'vue-orgchart/dist/style.min.css'
  import { VoBasic } from 'vue-orgchart'
  export default Vue.extend({
    components: { VoBasic },
    props: {
      data: Object,
      nodeClicked: { type: Function, required: false },
      nodeMouseEntered: { type: Function, required: false },
    },
    mounted () {
      $(document).on('click', '.node', e => {
        const node = e.currentTarget
        const data = JSON.parse(node.dataset.source)
        if (typeof this.nodeClicked === 'function') {
          this.nodeClicked(data)
        }
      })
      $(document).on('mouseenter', '.node', e => {
        const node = e.currentTarget
        const data = JSON.parse(node.dataset.source)
        if (typeof this.nodeMouseEntered === 'function') {
          this.nodeMouseEntered(data)
        }
      })
    },
    methods: {
    },
    computed: {
    }
  })
</script>

<style lang="scss">
    #chart-container {
        overflow: scroll !important;
        .node {
            cursor: pointer;
        }
        .orgchart .node .title {
            background-color: #eb5504 !important;
        }
        height: auto;
    }
</style>
