import KeyResult from 'models/key_result'
export default class Objective {
  id: number
  description: string
  position: number
  keyResults: KeyResult[]

  constructor(props) {
    this.id = props.id
    this.description = props.description
    this.position = props.position
    this.keyResults = props.keyResults || []
  }
}
