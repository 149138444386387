import '../stylesheets/application.scss'

import 'bootstrap/dist/js/bootstrap'

import '../utils/chart'
import '../utils/rails-ujs'
import '../utils/datepicker'
import '../utils/tooltip'
import '../utils/autosize'

import '../pages/teams'
import '../pages/okr'

import Vue from 'vue'

// Global event bus
Vue.prototype.$eventHub = new Vue()
Vue.prototype.$store = new Vue()
