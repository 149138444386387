export default class KeyResult {
  id: number
  description: string
  score: number
  comment: string

  constructor(props) {
    this.id = props.id
    this.description = props.description
    this.score = props.score
    this.comment = props.comment
  }
}
