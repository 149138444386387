import Objective from 'models/objective'
export default class OkrSummary {
  id: number
  objectives: Objective[]
  fixed: boolean

  constructor(props) {
    this.id = props.id
    this.objectives = props.objectives.map(objectiveProps => new Objective(objectiveProps)) || []
    this.fixed = props.fixed || false
  }
}
