<template lang='pug'>
  div
    .mb-3
      button.btn.btn-sm.btn-light(@click.prevent="addObjective" v-show="summary && !summary.fixed")
        i.fa.fa-plus-circle.mr-1
        | Add
      button.btn.btn-sm.btn-light.ml-2(@click.prevent="openTransferModal" v-show="summary && !summary.fixed")
        i.fa.fa-clone.mr-1
        | Copy
    draggable(v-model="objectives" @change="onPositionChanged" :options="{ disabled: !summary || summary.fixed }")
      transition-group(name="fade")
        .mb-3.p-4.border(v-for="(objective, objectiveIndex) in objectives" :key="'objective-'+objective.id")
          .d-flex.justify-content-between.mb-3
            .flex-grow-1.d-flex.align-items-center
              span.badge.badge-secondary.mr-2(v-show="summary.fixed") Fixed
              i.fa.fa-bars.mr-3.text-black-50.cursor-grab(v-show="!summary.fixed")
              editable-column.flex-grow-1(v-model="objective.description" :readonly="summary.fixed" @done="(v, c) => { objective.description = v; saveObjective(objective, objectiveIndex, c) }")
            a.text-danger.px-2(href='#' @click.prevent="deleteObjective(objective, objectiveIndex)" v-show="!summary.fixed")
              i.fa.fa-times
          score(:objective="objective")
          table.table.table-sm
            tr
              th Key Result
              th(style="width: 8rem;") Score
              th Comment
              th(style="width: 2rem;")
            transition-group(name="fade" tag="tbody")
              tr(v-for="(keyResult, keyResultIndex) in objective.keyResults" :key="'keyResult-'+keyResult.id")
                td
                  editable-column(type="textarea" v-model="keyResult.description" :readonly="summary.fixed" @done="(v, c) => { keyResult.description = v; saveKeyResult(keyResult, objective, keyResultIndex, c) }")
                td
                  editable-column(type="number" min="0" max="100" v-model="keyResult.score" :readonly="summary.fixed" @done="(v, c) => { keyResult.score = v; saveKeyResult(keyResult, objective, keyResultIndex, c) }")
                td
                  editable-column(type="textarea" v-model="keyResult.comment" :readonly="summary.fixed" @done="(v, c) => { keyResult.comment = v; saveKeyResult(keyResult, objective, keyResultIndex, c) }")
                td
                  a.text-danger(href='#' @click.prevent="deleteKeyResult(objective, keyResultIndex)" v-show="!summary.fixed")
                    i.fa.fa-times
          button.btn.btn-sm.btn-primary(@click.prevent="addKeyResult(objective)" v-show="!summary.fixed")
            i.fa.fa-plus-circle.mr-1
            | Key Result
    transfer-objective-modal(ref="transferObjectiveModal" @transfer="transferObjectives" :member-id="params.memberId")
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import jQuery from 'jquery'

// Models
import OkrSummary from 'models/okr_summary'
import Objective from 'models/objective'
import KeyResult from 'models/key_result'

// Components
import Draggable from 'vuedraggable'
import Score from 'components/okr/score.vue'
import EditableColumn from 'components/okr/editable-column.vue'
import TransferObjectiveModal from 'components/okr/transfer-objective-modal.vue'

// Repositories
import OkrRepository from 'repositories/okr_repository'
import ObjectiveRepository from 'repositories/objective_repository'
import KeyResultRepository from 'repositories/key_result_repository'

import params from '../../utils/params'

@Component({
  components: {
    Draggable,
    Score,
    EditableColumn,
    TransferObjectiveModal,
  }
})
export default class OkrApp extends Vue {
  params = params()
  summary: OkrSummary|null = null
  objectives: Objective[] = []

  created () {
    if (!this.params.summaryId) {
      throw Error(`Invalid URL. summaryId: ${this.params.summaryId}`)
    }

    OkrRepository.fetch(this.params.summaryId).then(summary => {
      this.summary = summary
      this.objectives = summary.objectives
    })
  }

  private addObjective () {
    const newObjective = new Objective({ description: `objective${this.objectives.length + 1}` })
    this.saveObjective(newObjective, null, null)
  }

  private saveObjective (objective: Objective, index, context) {
    if (objective.id) { // update
      ObjectiveRepository.update(objective).then(objective => {
        this.$set(this.objectives, index, objective)
        context?.complete() // FIXME: 子のデータを更新しないといけないのが微妙
      }).catch(err => {
        alert(err)
      })
    } else { // create
      ObjectiveRepository.create(objective, this.params.summaryId).then(objective => {
        this.objectives.push(objective)
        context?.complete() // FIXME
      }).catch(err => {
        alert(err)
      })
    }
  }

  private deleteObjective (objective: Objective, index: number): boolean {
    if (!confirm(`Objective: ${objective.description} を削除しますか？ (Key Resultも削除されます)`)) return false

    ObjectiveRepository.delete(objective).then(objective => {
      this.objectives.splice(index, 1)
    }).catch(err => {
      alert(err)
    })
    return true
  }

  private onPositionChanged(e) {
    const objective = e.moved.element
    const index = e.moved.newIndex
    objective.position = index
    ObjectiveRepository.update(objective).then(objective => {
      this.$set(this.objectives, index, objective)
    }).catch(err => {
      alert(err)
    })
  }

  private openTransferModal () {
    jQuery((this.$refs.transferObjectiveModal as Vue).$el).modal('show')
  }

  private transferObjectives (objectiveIds: number[]) {
    OkrRepository.transfer(objectiveIds, this.params.summaryId).then(summary => {
      this.objectives.splice(0)
      summary.objectives.forEach(objective => {
        this.objectives.push(objective)
      })
      jQuery((this.$refs.transferObjectiveModal as Vue).$el).modal('hide')
    }).catch(err => {
      alert(err)
    })
  }

  private addKeyResult (objective: Objective) {
    const newKeyResult = new KeyResult({ description: `KR${objective.keyResults.length + 1}`, score: 0 })
    this.saveKeyResult(newKeyResult, objective, null, null)
  }

  private saveKeyResult (keyResult: KeyResult, objective: Objective, index, context) {
    if (keyResult.id) {
      KeyResultRepository.update(keyResult).then(keyResult => {
        this.$set(objective.keyResults, index, keyResult)
        context?.complete() // FIXME
      }).catch(err => {
        alert(err)
      })
    } else {
      KeyResultRepository.create(objective, keyResult).then(keyResult => {
        objective.keyResults.push(keyResult)
        context?.complete() // FIXME
      }).catch(err => {
        alert(err)
      })
    }
  }
  private deleteKeyResult (objective: Objective, index: number): boolean {
    const keyResult = objective.keyResults[index]
    if (!confirm(`Key Result: ${keyResult.description} を削除しますか？`)) return false

    KeyResultRepository.delete(keyResult).then((res) => {
      objective.keyResults.splice(index, 1)
    }).catch(err => {
      alert(err)
    })
    return true
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    will-change: opacity;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.fade-leave-active {
    background-color: #eb5504;
}

.cursor-grab {
    cursor: grab;
}
</style>
