<template lang="pug">
  org-chart(:data="teams[0]" :node-clicked="pageMove")
</template>

<script lang="ts">
  import Vue from 'vue'
  import OrgChart from './org-chart'

  export default Vue.extend({
    components: {
      OrgChart
    },
    data() {
      return {
        teams: [],
        openedAll: false
      }
    },
    created () {
      fetch('/teams.json').then(res => res.json()).then(json => {
        this.teams = json
      })
    },
    methods: {
      toggleAll () {
        this.$eventHub.$emit(this.openedAll ? 'close-all' : 'open-all')
        this.openedAll = !this.openedAll
      },
      pageMove (node) {
        location.href = `/teams/${node.id}`
      }
    }
  })
</script>
