<template lang='pug'>
    span score {{score}}
</template>

<script lang="ts">
  import Vue from 'vue'

  import Objective from '../../models/objective'
  export default Vue.extend({
    props: {
      objective: { type: Objective, required: true }
    },
    computed: {
      score () {
        if (this.objective.keyResults.length === 0) {
          return 0
        }
        return Math.round(1.0 * this.objective.keyResults.reduce((sum, keyResult) => sum + keyResult.score, 0) / this.objective.keyResults.length)
      }
    }
  })
</script>
