import { render, staticRenderFns } from "./transfer-objective-modal.vue?vue&type=template&id=59271708&scoped=true&lang=pug&"
import script from "./transfer-objective-modal.vue?vue&type=script&lang=ts&"
export * from "./transfer-objective-modal.vue?vue&type=script&lang=ts&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./transfer-objective-modal.vue?vue&type=style&index=1&id=59271708&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59271708",
  null
  
)

export default component.exports