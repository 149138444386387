import * as queryString from 'query-string'

interface ParsedParams { [key: string]: any }

export default (): ParsedParams => {
  const params = queryString.parse(location.search)

  let match
  match = location.pathname.match(/\/summaries\/(\d+)/)
  if (match) params.summaryId = match[1]
  match = location.pathname.match(/\/teams\/(\d+)/)
  if (match) params.teamId = match[1]
  match = location.pathname.match(/\/members\/(\d+)/)
  if (match) params.memberId = match[1]

  const parsed: ParsedParams = {}
  for (let key in params) {
    if (String(params[key]).match(/^\d+$/)) {
      parsed[key] = parseInt(String(params[key]))
    } else {
      parsed[key] = params[key]
    }
  }

  return parsed
}
