import Rails from '@rails/ujs'

export default {
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': Rails.csrfToken()
  }
}
