import $ from 'jquery'
import 'bootstrap-datepicker'

$(function () {
  $.fn.datepicker.dates.ja = {
    days: ["日曜", "月曜", "火曜", "水曜", "木曜", "金曜", "土曜"],
    daysShort: ["日", "月", "火", "水", "木", "金", "土"],
    daysMin: ["日", "月", "火", "水", "木", "金", "土"],
    months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    today: "今日",
    format: "yyyy/mm/dd",
    titleFormat: "yyyy年mm月",
    clear: "クリア"
  }
  $('.datepicker').datepicker({ format: 'yyyy-mm-dd', language: 'ja', startView: 1 })
})
