import fetchOptions from '../utils/fetch_options'
import handleError from '../utils/handle_error'

import Objective from '../models/objective'

export default {
  create(objective: Objective, summaryId: number): Promise<Objective> {
    return new Promise((resolve, reject) => {
      fetch(`/internal_api/okr/summaries/${summaryId}/objectives.json`, Object.assign({
        method: 'POST',
        body: JSON.stringify({ objective })
      }, fetchOptions)).then(handleError).then(objectiveProps => {
        resolve(new Objective(objectiveProps))
      }).catch(err => {
        reject(err)
      })
    })
  },

  update(objective: Objective): Promise<Objective> {
    return new Promise((resolve, reject) => {
      fetch(`/internal_api/okr/objectives/${objective.id}.json`, Object.assign({
        method: 'PATCH',
        body: JSON.stringify({ objective })
      }, fetchOptions)).then(handleError).then(objectiveProps => {
        resolve(new Objective(objectiveProps))
      }).catch(err => {
        reject(err)
      })
    })
  },

  delete(objective: Objective) {
    return new Promise((resolve, reject) => {
      fetch(`/internal_api/okr/objectives/${objective.id}.json`, Object.assign({
        method: 'DELETE'
      }, fetchOptions)).then(handleError).then(res => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}
