import fetchOptions from '../utils/fetch_options'
import handleError from '../utils/handle_error'

import OkrSummary from '../models/okr_summary'
import Objective from "models/objective";

export default {
  fetch(summaryId: number): Promise<OkrSummary> {
    return new Promise(resolve => {
      fetch(`/internal_api/okr/summaries/${summaryId}`, fetchOptions).then(res => res.json()).then(props => {
        resolve(new OkrSummary(props))
      })
    })
  },

  transfer(objectiveIds: number[], summaryId: number): Promise<OkrSummary> {
    return new Promise((resolve, reject) => {
      const url = `/internal_api/okr/summaries/${summaryId}/transfer.json`
      const body = JSON.stringify({
        objective_ids: objectiveIds
      })
      fetch(url, Object.assign({ method: 'POST', body }, fetchOptions)).then(res => res.json()).then(props => {
        resolve(new OkrSummary(props))
      }).catch(err => {
        reject(err)
      })
    })
  }
}
