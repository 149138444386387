<template lang="pug">
  .modal.fade(role="dialog" tabindex="-1" ref="modal")
    .modal-dialog.modal-lg(role="document")
      .modal-content
        .modal-header
          h5.modal-title OKR検索
          button.close(aria-label="Close" data-dismiss="modal" type="button")
            span(aria-hidden="true") &times;
        .modal-body
          .d-flex
            multiselect(v-model="search.member" :options="members" label="name" track-by="name" selectLabel="" deselectLabel="" :max-height="700")
            select.form-control(v-model="search.year")
              option(v-for="year in yearOptions") {{year}}
            select.form-control(v-model="search.month")
              option(v-for="month in monthOptions") {{month}}
            button.btn.btn-primary.btn-block(@click="searchObjectives") 検索
          ul.list-unstyled.my-3
            li(v-for="result in searchResults")
              label
                input(type="checkbox" :value="result.id" v-model="selectedIds")
                span.px-2(:class="selectedIds.includes(result.id) ? 'text-primary' : ''") {{result.description}}
        .modal-footer
          button.btn.btn-primary(type="button" @click="transfer" :disabled="selectedIds.length === 0") 選択したObjectiveをコピーする
</template>

<script lang="ts">
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import Multiselect from 'vue-multiselect'

  interface Member {
    id: number,
  }

  interface Search {
    member: Member | null,
    year: number,
    month: number,
  }

  @Component({
    components: {
      Multiselect,
    }
  })
  export default class TransferObjectiveModal extends Vue {
    @Prop() memberId: number

    members: Member[] = []
    searchResults: object[] = []
    search: Search = (() => {
      const now = new Date()
      const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      return { member: null, year: prevMonth.getFullYear(), month: prevMonth.getMonth() + 1 }
    })()
    selectedIds: number[] = []

    created() {
      // TODO: internal_api に移管する
      fetch('/members.json?context=transfer-objective').then(res => res.json()).then(data => {
        this.members = data
        this.search.member = this.members.find(member => member.id === this.memberId) || null
      })
    }

    private get yearOptions() {
      const today = new Date()
      return [today.getFullYear() - 1, today.getFullYear()]
    }

    private get monthOptions() {
      // FIXME: もっとスマートに書きたい
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }

    private searchObjectives () {
      fetch(`/internal_api/okr/members/${this.search.member?.id}/objectives.json?year=${this.search.year}&month=${this.search.month}`).then(res => res.json()).then(data => {
        this.searchResults = data
        if (data.length === 0) {
          alert('見つかりませんでした')
        }
      })
    }

    private  transfer() {
      this.$emit('transfer', this.selectedIds)
      this.searchResults = []
      this.selectedIds = []
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  ::v-deep .multiselect {
    .multiselect__option--highlight {
      background-color: #eb5504;
    }
  }
</style>
