import fetchOptions from '../utils/fetch_options'
import handleError from '../utils/handle_error'

import Objective from 'models/objective'
import KeyResult from 'models/key_result'

export default {
  create(objective: Objective, keyResult: KeyResult): Promise<KeyResult> {
    return new Promise((resolve, reject) => {
      fetch(`/internal_api/okr/objectives/${objective.id}/key_results.json`, Object.assign({
        method: 'POST',
        body: JSON.stringify({ key_result: keyResult })
      }, fetchOptions)).then(handleError).then(keyResultProps => {
        resolve(new KeyResult(keyResultProps))
      }).catch(err => {
        reject(err)
      })
    })
  },

  update(keyResult: KeyResult): Promise<KeyResult> {
    return new Promise((resolve, reject) => {
      fetch(`/internal_api/okr/key_results/${keyResult.id}.json`, Object.assign({
        method: 'PATCH',
        body: JSON.stringify({ key_result: keyResult })
      }, fetchOptions)).then(handleError).then(keyResultProps => {
        resolve(new KeyResult(keyResultProps))
      }).catch(err => {
        reject(err)
      })
    })
  },

  delete(keyResult: KeyResult) {
    return new Promise((resolve, reject) => {
      fetch(`/internal_api/okr/key_results/${keyResult.id}.json`, Object.assign({
        method: 'DELETE'
      }, fetchOptions)).then((res) => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}
